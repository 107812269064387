
import { mapGetters, mapMutations, mapState } from "vuex";
import myDesignModal from '~/components/modal/my-designs.vue'
import NeedHelpButton from '~/components/UI/need-help-btn.vue'
import { BIconChevronRight, BIconStarFill, BIconX } from "bootstrap-vue"; // BIconStarFill
export default {
  components: {
    BIconChevronRight,
    BIconStarFill,
    BIconX,
    myDesignModal,
    NeedHelpButton
  },
  validate({ params }) {
    return /^\d+$/.test(params.id);
  },
  async asyncData({ params, $axios, $replaceSEO }) {
    const seo = await $axios.get("sitemaps/products_standard");
    let productDetail = await $axios.$get(`standard-products/${params.id}`)
    productDetail.price = [ { price: 0 } ]
    const packaingTypes = await $axios.$get("packaging-types");

    const images = productDetail.images;

    const promoCode = await $axios.get('get-promo-codes')
    const promoCodeData = promoCode.data
    const lastPromoCode = await $axios.get('get-last-promocode-active')
    let promoCodePopup = null
    if (lastPromoCode) {
      promoCodePopup = lastPromoCode.data
    }

    // const priceRangs = [
    //   { range: 'ต่ำกว่า 1000', price: '5.85' },
    //   { range: '1000-2999', price: '5.4' },
    //   { range: '3000-4999', price: '4.95' },
    //   { range: 'มากกว่า 5,000', price: '4.5' }
    // ]

    // const images = [
    //   { src: require('~/static/images/products/image18.png') },
    //   { src: require('~/static/images/products/image18_2.png') }
    // ]

    const imageSelected = images[0];

    const customers = [];

    const faq = [];

    const relations = [
      {
        img: require("~/static/images/products/Rectangle01.png"),
        title: "ขวดสปาเล็ก PETG ทรงกระบอก ไหล่ตัด สีใส 30cc ฝาเกลียวอลูมิเนียม",
        price: 3.01,
      },
      {
        img: require("~/static/images/products/Rectangle02.png"),
        title: "ขวดสปาเล็ก PETG ทรงกระบอก ไหล่ตัด สีใส 30cc ฝาเกลียวอลูมิเนียม",
        price: 3.01,
      },
    ];
    let unitName = productDetail.unit_name
    let designPrice = productDetail.design_price
    /* let unitName = "ชิ้น";
    let designPrice = 0
    if (productDetail.product_type === 1) {
      unitName = "ขวด";
    } else if (productDetail.product_type === 2) {
      unitName = "ชิ้น";
    } else if (productDetail.product_type === 3) {
      productDetail.delivery_day = 3
      unitName = "ซอง";
    } else if (productDetail.product_type === 4) {
      productDetail.delivery_day = 30
      designPrice = 3000
      unitName = "ซอง";
    } else if (productDetail.product_type === 5) {
      productDetail.delivery_day = 15
    } else if (productDetail.product_type === 6) {
      designPrice = 2850
      productDetail.delivery_day = 9
    } else if ( productDetail.product_type === 7 ) {
      designPrice = 2850
      productDetail.delivery_day = productDetail.can_design == 1 ? 30 : 3
    } */

    let w = null
    let l = null
    let h = 0
    let s = 0
    let b = 0
    let mapping_input_id = 0
    let paper_id = 0

    if (productDetail.product_type === 6) {
      w = productDetail.w
      l = productDetail.l
    } else if (productDetail.product_type === 4 && [460,461,462,463,464,465,466,467].includes(productDetail.id)) {
      w = productDetail.w
      l = productDetail.l
      h = 0
      s = 0
      b = productDetail.d
      if ([460,461,462,463].includes(productDetail.id)) {
        paper_id = 82
      } else {
        paper_id = 83
      }
    } else {
      w = productDetail.sticker_front_w
      l = productDetail.sticker_front_l
    }

    return {
      packaing_types: packaingTypes.data,
      imageSelected,
      images,
      customers,
      faq,
      relations,
      productDetail,
      designPrice,
      id: params.id,
      seo: $replaceSEO(
        seo.data.data,
        productDetail.name,
        params.id,
        null,
        null,
        null
      ),
      unitName,
      mapping_input_id,
      paper_id,
      w,
      l,
      h,
      s,
      b,
      promoCode: promoCode.data,
      promoCodePopup,
      promoCodeData
    };
  },
  data() {
    return {
      promoCodeSelect: null,
      orderKeyRef: "",
      selected_category_id: 0,
      slide: 0,
      sliding: null,
      faqActive: null,
      amount: "",
      zipCode: "",
      sizeSelected: null,
      colorSelected: null,
      addOnFolding: 0,
      addOnSticker: 0,
      iframeHeight: "800px",
      loadingLastDesign: false,
      addOnList: [
        { text: "ต้องการ", value: "1" },
        { text: "ไม่ต้องการ", value: "0" },
      ],
      deliveryPrice: 0,
      uuid: '',
      hasDesign: false,
      designData: {},
      timer: null,
      packsizeAmount: 1,
      useDesignTool: false,
      provideTemplate: true,
      design: false,
      drawingData:
        typeof withDrawing !== "undefined" && withDrawing === true
          ? this.drawingData
          : "",
      drawingPNG:
        typeof withDrawing !== "undefined" && withDrawing === true
          ? this.drawingPNG
          : "",
      couponActive: false,
      promoCodeTxt: '',
      discountType: 0,
      discount: 0,
      discount_type: 0,
      disabledOrderBtn: true,
      alertMsg: '',
      discountCodeTemplate: false,
      redeemPointTemplate: false,
      reward: [],
      is_redeem: false
    };
  },
  computed: {
    ...mapState(["userData"]),
    ...mapGetters(["isAuthenticated"]),
  },
  async mounted() {
    this.showLoading();
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "pageInfo",
      page: {
        type: "product",
        path: `${this.$route.path}`,
        title: this.seo.title,
      },
      user: {
        customerID: this.userData ? this.userData.uid : "",
        customerEmail: this.userData ? this.userData.email : "",
      },
    });
    this.colorSelected = this.productDetail.color_id;
    this.sizeSelected = this.id;
    // console.log(this.productDetail)
    /* if (
      localStorage.getItem("myZipcode") !== null &&
      localStorage.getItem("myZipcode") !== ""
    ) {
      this.zipCode = localStorage.getItem("myZipcode");
      this.getPrices()
    } else {
      // this.$bvModal.show("modalMyZipcode");
      this.$refs.modalMyZipcode.show()
    } */
    // this.$refs.modalMyZipcode.show()
    this.hideLoading()

    window.objCurrentPage = this;
    window.closeDesignNew = function () {
      window.objCurrentPage.$bvModal.hide("modalDesigner");
      return true;
    };
    window.showLoadingFromIframe = function () {
      window.objCurrentPage.showLoading()
      return true
    }
    window.hideLoadingFromIframe = function () {
      window.objCurrentPage.hideLoading();
    };
    window.closeAndSaveDesignNew = function (uuid, saveOnly) {
      window.objCurrentPage.uuid = uuid;
      if (typeof window.objCurrentPage.$refs.myDesignModal.reloadDesigns !== 'undefined' &&
        window.objCurrentPage.$refs.myDesignModal.show) {
        window.objCurrentPage.$refs.myDesignModal.reloadDesigns()
      }
      const dataStore = {
        params: window.objCurrentPage.prepareData(false),
        factorySelected: window.objCurrentPage.factorySelected,
        fromModalDesigner: true,
      };
      window.objCurrentPage.storeOrder2LocalStorage(dataStore);
      if (saveOnly === false) {
        window.objCurrentPage.$bvModal.hide("modalDesigner");
        window.objCurrentPage.$bvModal.show("modal-warning-order");
      }
      window.objCurrentPage.getLastDesign();
      return true;
    };
    window.closeAndSaveSemiDesign = function (uuid) {
      window.objCurrentPage.uuid = uuid;
      const dataStore = {
        params: window.objCurrentPage.prepareData(false),
        factorySelected: window.objCurrentPage.factorySelected,
        fromModalDesigner: true,
      };
      window.objCurrentPage.storeOrder2LocalStorage(dataStore);
      window.objCurrentPage.$bvModal.hide("modalDesigner");
      window.objCurrentPage.getLastDesign();
      return true;
    };
    window.closeAndSaveDesign = function (data) {
      window.objCurrentPage.$bvModal.hide("modalDesigner");
      window.objCurrentPage.setDrawingData(data);
      return true;
    };
    window.savePngFromDesigner = function (data) {
      // console.log('savePngFromDesigner', data)
      window.objCurrentPage.setDrawingPNG(data);
      return true;
    };
    window.returnDrawingData = function () {
      return window.objCurrentPage.drawingData;
    };

    this.orderKeyRef = "orderStdProduct" + this.id;

    const h =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    this.iframeHeight = h - 10;

    if (localStorage.getItem('after-login-then') === 'opendesigner') {
      this.useDesignTool = true
      this.openModalDesigner()
      localStorage.removeItem('after-login-then')
    }

    if (this.isAuthenticated && this.useDesignTool === true) {
      this.getLastDesign();

    }

    if (this.productDetail.product_type === 1) {
      this.amount = 100;
      this.calculateAmount()
    }
    if (this.productDetail.product_type === 2 && this.productDetail.factory_id !== 24) {
      this.packsizeAmount = 4
      this.calculatePack(this.productDetail.packsize)
    } else if (this.productDetail.product_type === 4) {
      this.packsizeAmount = 10
      this.calculatePack(this.productDetail.packsize)
    } else if ([3, 5, 6].includes(this.productDetail.product_type)) {
      this.packsizeAmount = 1
      this.calculatePack(this.productDetail.packsize)
    } else {
      this.packsizeAmount = 1
      this.calculatePack(this.productDetail.packsize)
    }
    if (this.$route.query.hasDesign) {
      this.hasDesign = true
      this.uuid = this.$route.query.uuid
      this.design = false
      this.useDesignTool = true
      this.provideTemplate = false
    }
    if (this.isAuthenticated) {
      try {
        // this.$axios.$get('rewards/packaging_type_id/' + this.productDetail.product_type).then((res) => {
        this.$axios.$get('rewards/packaging_type_id/4').then((res) => {
          this.reward = res
        })
      } catch (err) {
        this.setAlert({
          show: true,
          message: err.message,
          header: 'แจ้งเตือน'
        })
        this.hideLoading()
      }
    }
  },
  methods: {
    ...mapMutations(["showLoading", "hideLoading", "setAlert", "setAlertWithLine"]),
    getLabelOfPacksize () {
      let retStr = ''
      if (this.productDetail.delivery_packsize_per_pack > 1 && this.productDetail.packsize >= 1) {
        const tmp = (this.productDetail.packsize*this.productDetail.delivery_packsize_per_pack)
        retStr = this.productDetail.product_type === 7 ? 'จำนวนกล่อง (' + tmp + ' ' + this.unitName +'/กล่อง)' : 'จำนวนแพ็ค (' + tmp + ' ' + this.unitName +'/แพ็ค)'
      } else if (this.productDetail.packsize === 1) {
        retStr =  'จำนวน' + this.unitName
      } else {
        retStr =  'จำนวนแพ็ค (' + this.productDetail.packsize + ' ' + this.unitName +'/แพ็ค)'
      }
      return retStr + ''
    },
    openMyDesignModal (design) {
      this.showLoading()
      this.uuid = design.uuid
      this.$bvModal.show('modalDesigner')
    },
    orderDesign (design) {
      this.uuid = design.uuid
      this.useDesignTool = true
      this.hasDesign = true
    },
    toggleDesignMode() {
      if (this.design === true) {
        this.useDesignTool = false;
        this.provideTemplate = false;
        this.designPrice = this.productDetail.design_price;
      }
    },
    toggleProvideTemplate() {
      if (this.provideTemplate === true) {
        this.useDesignTool = false;
        this.design = false;
        this.designPrice = 0;
      }
    },
    toggleUseDesignTool() {
      if (this.useDesignTool === true) {
        this.design = false;
        this.provideTemplate = false;
        this.getLastDesign();
        this.designPrice = 0;
      }
    },
    getUrlDesigner() {
      let patternName = this.$getPatternName(this.productDetail.product_type, this.productDetail.locopack_code)
      let url = `/designer?flow=edit`;
      if (this.uuid) {
        url += `&uuid=${this.uuid}`
      }
      if (patternName) {
        url += `&patternName=${patternName}&width=${this.w}&length=${this.l}&depth=${this.h}`
      }
      return url;
    },
    isNumber(evt) {
      if (!evt) {
        evt = window.event;
      }
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    async getLastDesign() {
      if (this.isAuthenticated) {
        this.loadingLastDesign = true;
        const patternName = this.$getPatternName(this.productDetail.product_type, this.productDetail.locopack_code)
        const res = await this.$axios.$get('/designs/get-designs-with-size', {
          params: {
            mapping_input_id: this.mapping_input_id,
            patternName: patternName,
            w: this.w,
            l: this.l,
            h: this.h,
            s: this.s,
            b: this.b,
            flute: '',
            paper_id: this.paper_id,
            standard_product_id: this.id
          }
        })
        const userDesigns = res.data
        if (userDesigns.length > 0) {
          this.hasDesign = true
          this.useDesignTool = true
          this.provideTemplate = false
        } else {
          this.designData = {}
        }
        this.loadingLastDesign = false
      }
    },
    prepareData(withDrawing) {
      localStorage.setItem("myZipcode", this.zipCode);
      const data = {
        drawingData:
          typeof withDrawing !== "undefined" && withDrawing === true
            ? this.drawingData
            : "",
        drawingPNG:
          typeof withDrawing !== "undefined" && withDrawing === true
            ? this.drawingPNG
            : "",
      };

      return data;
    },
    storeOrder2LocalStorage(orderData) {
      if (process.client) {
        localStorage.setItem(this.orderKeyRef, JSON.stringify(orderData));
      }
    },
    getOrder2LocalStorage(orderData) {
      if (process.client) {
        return JSON.parse(localStorage.getItem(this.orderKeyRef));
      }
    },
    async  openModalMyDesigns () {
      if (this.isAuthenticated) {
        const patternName = this.$getPatternName(this.productDetail.product_type, this.productDetail.locopack_code)
        await this.$refs.myDesignModal.getMyDesigns({
          mapping_input_id: this.mapping_input_id,
          patternName: patternName,
          w: this.w,
          l: this.l,
          h: this.h,
          s: this.s,
          b: this.b,
          flute: '',
          select_size_type: 0,
          design_type: 'normal',
          r: 0,
          dim: 0,
          paper_id: this.paper_id,
          standard_product_id : this.id,
          locopack_code : this.productDetail.locopack_code
        })
        // this.$refs.myDesignModal.getMyDesigns(myDesignParams)
        this.hideLoading()
        this.$refs.myDesignModal.show = true
        this.$refs.myDesignModal.subHeader = `${this.productDetail.name} `
      }
    },
    async openModalDesigner() {
      if (!this.isAuthenticated) {
        const dataStore = {
          params: this.prepareData(true),
          factorySelected: this.factorySelected,
          fromModalDesigner: true,
        };
        this.storeOrder2LocalStorage(dataStore);
        localStorage.setItem(
          "click-login-from-page",
          "products-detail-id"
        );
        localStorage.setItem('after-login-then', 'opendesigner')
        localStorage.setItem(
          "click-login-from-page-params",
          JSON.stringify({ id: this.id })
        );
        this.$router.push(this.localeLocation({ name: 'auth-login-via-phone' }))
      } else {
        this.showLoading();
        const patternName = this.$getPatternName(this.productDetail.product_type, this.productDetail.locopack_code)
        const res = await this.$axios.$get('/designs/get-designs-with-size', {
          params: {
            mapping_input_id: this.mapping_input_id,
            patternName: patternName,
            w: this.w,
            l: this.l,
            h: this.h,
            s: this.s,
            b: this.b,
            flute: '',
            paper_id: this.paper_id,
            standard_product_id: this.id
          }
        })

        const userDesigns = res.data
        if (userDesigns.length > 0) {
          this.openModalMyDesigns()
        } else {
          const userDesign = await this.$axios.$post('/designs/create-designs', {
            mapping_input_id: this.mapping_input_id,
            patternName: patternName,
            w: this.w,
            l: this.l,
            h: this.h,
            s: this.s,
            b: this.b,
            flute: '',
            select_size_type: 0,
            design_type: 'normal',
            r: 0,
            dim: 0,
            paper_id: this.paper_id,
            standard_product_id: this.id
          })
          this.uuid = userDesign.data.uuid
          this.$bvModal.show('modalDesigner')
        }
      }
    },
    setDrawingData(data) {
      this.drawingData = data;
    },
    setDrawingPNG(data) {
      this.drawingPNG = data;
    },
    saveZipcode() {
      if (this.zipCode != '' && this.zipCode.length >= 5) {
        localStorage.setItem("myZipcode", this.zipCode);
        this.getPrices()
        this.$refs.modalMyZipcode.hide()
      }
    },
    async getPrices () {
      var prices = await this.$axios.$get(`standard-products/${this.id}/zipcode/${this.zipCode}/step-prices`)
      // var prices = await this.$axios.$get("rigids/" + this.id + "/getPrices", { params: {zipcode: this.zipCode} })
      if (prices.status == 1) {
        this.productDetail.price = prices.data
        this.disabledOrderBtn = false
      }else{
        this.productDetail.price = [ { price: 0 } ]
        this.disabledOrderBtn = true
        this.alertMsg = prices.message
        this.$bvModal.show('modal-show-alert')
      }
    },
    calculatePack(packsize) {
      if (this.productDetail.delivery_packsize_per_pack > 0) {
        this.amount = this.packsizeAmount * packsize * this.productDetail.delivery_packsize_per_pack;
      } else {
        this.amount = this.packsizeAmount * packsize;
      }
      if (this.productDetail.product_type === 6 && this.amount > 0) {
        if (this.amount >= 1 && this.amount <= 100) {
          this.productDetail.delivery_day = 9
        } else if (this.amount >= 101 && this.amount <= 200) {
          this.productDetail.delivery_day = 12
        } else if (this.amount >= 201) {
          this.productDetail.delivery_day = 30
        } else {
          this.productDetail.delivery_day = 9
        }
      }
      // this.calculateAmount();
    },
    calculateAmount() {
      if (parseInt(this.amount) > 0 && this.zipCode.length === 5) {
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }

        this.timer = setTimeout(async () => {
          const calculateData = await this.$axios.$get(
            `/rigids/${this.id}/calculate`,
            {
              params: {
                amount: this.amount,
                zipcode: this.zipCode,
              },
            }
          );

          this.deliveryPrice = calculateData.delivery_price;
        }, 1500);
      } else {
        this.deliveryPrice = 0;
      }
    },
    changeZipcode () {
      if (this.zipCode.length == 5) {
        this.$refs.tooltip.$emit('close')
        this.$refs.tooltip.$emit('disable')
        this.getPrices()
      } else {
        this.$refs.tooltip.$emit('open')
        this.$refs.tooltip.$emit('enable')
      }
    },
    selectPromoCode () {
      var total = this.amount ? this.pricePerPiece(this.productDetail) * this.amount : 0
      if(total > 0 && this.promoCodeSelect){
        this.$axios.$get("promotions/calculate", { params: {total: total, code: this.promoCodeSelect.code} }).then((res) => {
          this.discount = res
          this.discount_type = 1
        })
      }
    },
    clearPromoCode () {
      this.promoCodeSelect = null
      this.promoCodeTxt = ''
      this.discount = 0
      this.discount_type = 0
      this.is_redeem = false
    },
    selectRedeem () {
      var reward = this.reward ? this.reward : null
      this.$axios.$get("redeem/calculate", { params: {reward_id: reward.id, product_type_id: this.productDetail.product_type} }).then((res) => {
        this.discount = res
        this.discount_type = 3
      })
    },
    keypressPromoCode () {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        var total = this.amount ? this.pricePerPiece(this.productDetail) * this.amount : 0
        if(total > 0 && this.promoCodeTxt.length > 0){
          this.$axios.$get("promotions/calculate", { params: {total: total, code: this.promoCodeTxt} }).then((res) => {
            this.discount = res
          })
        }
      }, 1500)
    },
    checkoutCheck() {
      const patternName = this.$getPatternName(this.productDetail.product_type, this.productDetail.locopack_code)
      if (['shelf', 'table'].includes(patternName) || [2, 5].includes(this.productDetail.product_type)) {
        if (this.productDetail.factory_id !== 24) {
          this.$bvModal.show('modalPaperColor')
        } else {
          this.order()
        }
      } else {
        this.order()
      }
    },
    async toCustomize() {
      if (this.zipCode.length !== 5) {
        this.setAlert({
          show: true,
          message: "กรุณาตรวจสอบและกรอกรหัสไปรษณีย์",
          header: "แจ้งเตือน",
        });
        return;
      }
      if (this.productDetail.product_type === 4) {
        if (parseInt(this.amount) === 0 ||
          parseInt(this.amount) < 1000 ||
          isNaN(parseInt(this.amount))) {
          this.setAlertWithLine({
            show: true,
            message: "จำนวนขั้นต่ำ 1000 ชิ้น",
            header: "แจ้งเตือน",
          });
          return;
        }
      } else {
        if (this.productDetail.delivery_packsize_per_pack > 0) {
          const minAmt = this.packsizeAmount * this.productDetail.packsize * this.productDetail.delivery_packsize_per_pack;
          if (parseInt(this.amount) < minAmt) {
            this.setAlertWithLine({
              show: true,
              message: "จำนวนขั้นต่ำ " + minAmt + " ชิ้น",
              header: "แจ้งเตือน",
            });
            return;
          }
        } else  if (parseInt(this.amount) === 0 ||
          (parseInt(this.amount) < 100 && (this.productDetail.product_type !== 5 && this.productDetail.product_type !== 6))||
          isNaN(parseInt(this.amount))) {
          this.setAlertWithLine({
            show: true,
            message: "จำนวนขั้นต่ำ 100 ชิ้น",
            header: "แจ้งเตือน",
          });
          return;
        }
      }
      this.showLoading();
      let designType = ''
      if (this.useDesignTool)  {
        designType = 'online'
      } else if (this.provideTemplate)  {
        designType = 'template'
      } else if (this.design)  {
        designType = 'design'
      }

      const patternName = this.$getPatternName(this.productDetail.product_type, this.productDetail.locopack_code)
      if (this.useDesignTool && this.uuid === "") {
        const userDesign = await this.$axios.$post('/designs/create-designs', {
          mapping_input_id: this.mapping_input_id,
          patternName: patternName,
          w: this.w,
          l: this.l,
          h: this.h,
          s: this.s,
          b: this.b,
          flute: '',
          select_size_type: 0,
          design_type: 'normal',
          r: 0,
          dim: 0,
          paper_id: this.paper_id,
          standard_product_id: this.id
        })
        this.uuid = userDesign.data.uuid
      }
      let checkoutData = {
        zipcode: this.zipCode,
        amount: this.amount,
        promo_code : (this.promoCodeSelect) ? this.promoCodeSelect.code : this.promoCodeTxt,
        discount_type : (this.promoCodeSelect) ? 1 : 0,
        uuid: this.uuid,
        design: designType,
        reward_id: this.reward.id ? this.reward.id : null,
        is_redeem: this.is_redeem
      }
      localStorage.setItem(`checkout-std-product-${this.id}`, JSON.stringify(checkoutData))
      this.$router.push(this.localeLocation(`/products/detail/${this.id}/checkout`))
      /* this.$router.push(this.localeLocation({
        name: "products-customize",
        query: {
          sticker: this.addOnSticker,
          folding: this.addOnFolding,
          id: this.id,
          zipcode: this.zipCode,
          amount: this.amount,
          promo_code : (this.promoCodeSelect) ? this.promoCodeSelect.code : this.promoCodeTxt,
          discount_type : (this.promoCodeSelect) ? 1 : 0,
          uuid: this.uuid,
          design: designType,
          reward_id: this.reward.id ? this.reward.id : null,
          is_redeem: this.is_redeem
        },
      })); */
    },
    changeOption(productId) {
      this.$router.push(this.localeLocation({
        name: "products-detail-id",
        params: { id: productId },
      }));
    },
    order() {
      if (this.zipCode.length !== 5) {
        this.setAlert({
          show: true,
          message: "กรุณาตรวจสอบและกรอกรหัสไปรษณีย์",
          header: "แจ้งเตือน",
        });
        return;
      }
      if (this.productDetail.product_type === 4) {
        if (
          parseInt(this.amount) === 0 ||
          parseInt(this.amount) < 1000 ||
          isNaN(parseInt(this.amount)) ||
          this.amount === ""
        ) {
          this.setAlert({
            show: true,
            message: "จำนวนขั้นต่ำ 1000 ชิ้น",
            header: "แจ้งเตือน",
          });

          return;
        }
      } else {
        if (
          parseInt(this.amount) === 0 ||
          (parseInt(this.amount) < 100 && (this.productDetail.product_type !== 5 && this.productDetail.product_type !== 6) ) ||
          isNaN(parseInt(this.amount)) ||
          this.amount === ""
        ) {
          this.setAlert({
            show: true,
            message: "จำนวนขั้นต่ำ 100 ชิ้น",
            header: "แจ้งเตือน",
          });

          return;
        }
      }
      if (this.productDetail.product_type === 1) {
        this.$refs.addOn.show();
      } else {
        this.toCustomize();
      }
    },
    faqActiveFn(index) {
      if (this.faq[index].active === false) {
        this.faq[index].active = true;
      } else {
        this.faq[index].active = false;
      }
      this.$root.$emit("bv::toggle::collapse", `collapse-faq-${index}`);
    },
    change_category(e, productCategoryId) {
      e.preventDefault();
      this.selectedCategoryId = productCategoryId;
    },
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
    setProduct(Product) {
      // this.$ga.event('PurchaseFlow', 'Click', 'CustomProductPattern-' + Product.id)
      this.$refs.modal.ModalProduct = Product;
    },
    pricePerPiece(product) {
      for (const idx in product.price) {
        const amount = this.amount === "" ? 0 : this.amount;
        if (
          product.price[idx].min <= amount &&
          product.price[idx].max >= amount
        ) {
          return product.price[idx].price;
        }
      }
      return 0;
    },
    toggleDiscountCodeTemplate () {
      this.promoCodeSelect = null
      this.promoCodeTxt = ''
      this.discount = 0
      this.discount_type = 0
      this.is_redeem = false

      if (this.discountCodeTemplate === true) {
        this.redeemPointTemplate = false
      }
    },
    toggleRedeemPointTemplate () {
      this.promoCodeSelect = null
      this.promoCodeTxt = ''
      this.discount = 0
      this.discount_type = 0
      this.is_redeem = false

      if (this.redeemPointTemplate === true) {
        this.discountCodeTemplate = false

        if (this.isAuthenticated) {
          try {
            this.$axios.$get('point-users/' + this.userData.uid + '/point').then((res) => {
              if (res.total_point < this.reward.points) {
                this.setAlert({
                  show: true,
                  message: 'พอยท์ที่มีอยู่ ไม่พอที่จะใช้งาน',
                  header: 'แจ้งเตือน'
                })
                this.redeemPointTemplate = false
              } else {
                this.is_redeem = true
                this.selectRedeem()
              }
            })
          } catch (err) {
            this.setAlert({
              show: true,
              message: err.message,
              header: 'แจ้งเตือน'
            })
            this.hideLoading()
          }
        }
      }
    },
  },
  head() {
    return {
      title: this.seo.title,
      meta: [
        {
          hid: "description",
          name: "description",
          content: this.seo.description,
        },
        { hid: "keywords", name: "keywords", content: this.seo.keywords },
      ],
      link: [
        {
          rel: "canonical",
          href: this.seo.canonical_url,
        },
      ],
    };
  },
};
