
import { mapMutations, mapState } from 'vuex'
// import { BIconFilter } from 'bootstrap-vue' // BIconStarFill
import InfiniteLoading from 'vue-infinite-loading'
import NeedHelpPopup from '~/components/UI/need-help-popup.vue'
export default {
  components: {
    // BIconFilter,
    NeedHelpPopup, InfiniteLoading
  },
  async asyncData ({ route, $axios, $replaceSEO }) {
    let seo
    if (route.query.sub) {
      seo = await $axios.get('sitemaps/products_category_sub')
    } else if (route.query.category) {
      seo = await $axios.get('sitemaps/products_category')
    } else {
      seo = await $axios.get('sitemaps/products')
    }
    const categories = await $axios.$get('/categories')
    // categories.push({ id: 0, name: 'สินค้าทั้งหมด', packaging_type_id: null, product_type: null })
    categories.unshift({ id: 0, name: 'สินค้าทั้งหมด' })
    const selectedPackagingType = route.query.category ?? null
    const selectedProductType = route.query.type ?? null
    const findCategory = categories.find((c) => {
      // eslint-disable-next-line eqeqeq
      return c.packaging_type_id == selectedPackagingType && c.product_type == selectedProductType
    })
    const currCategory = findCategory ?? { id: 0, name: 'สินค้าทั้งหมด' }
    const currCategoryId = currCategory.id
    /* let productsData = []
    const params = {
      params: {
        packaging_type_id: route.query.category,
        sub_types: route.query.sub
      }
    }
    if (route.query.type) {
      params.params.product_type = route.query.type
    }
    productsData = await $axios.$get('/products', params)
    let filterData = []
    for (const index in productsData.categories) {
      if (productsData.categories[index].packaging_type_id === 4) {
        filterData = productsData.categories[index].filter_data
      }
    }

    const packaingTypes = await $axios.$get('packaging-types')

    let titleFilter = 'บรรจุภัณฑ์ทั้งหมด'
    let titleSub = ''
    productsData.categories[0].item = undefined
    productsData.categories[0].sub = null
    const titleNames = []
    productsData.categories.forEach((item) => {
      if (item.packaging_type_id !== undefined) {
        if (item.product_type !== undefined) {
          if (item.sub_types !== undefined) {
            titleNames[item.packaging_type_id + '-' + item.product_type] = item.name
            for (const x in item.sub_types) {
              titleNames[item.packaging_type_id + '-' + item.product_type + '-' + item.sub_types[x].id] = { name: item.name, sub: item.sub_types[x].name }
            }
          } else {
            titleNames[item.packaging_type_id + '-' + item.product_type] = item.name
          }
        } else {
          titleNames[item.packaging_type_id + '-n'] = item.name
        }
      }
    })
    if (route.query.category !== undefined) {
      if (parseInt(route.query.category) === 4) {
        if (route.query.sub !== undefined) {
          titleFilter = titleNames[route.query.category + '-' + route.query.type + '-' + route.query.sub].name
          titleSub = titleNames[route.query.category + '-' + route.query.type + '-' + route.query.sub].sub
        } else {
          titleFilter = titleNames[route.query.category + '-' + route.query.type]
        }
      } else {
        titleFilter = titleNames[route.query.category + '-n']
      }
    } */
    return {
      /* packaing_types: packaingTypes.data,
      selectedCategoryId: route.query.category,
      filterData,
      rigidsData,
      productsData,
      packagingTypeId: parseInt(route.query.category),
      subTypeId: route.query.sub,
      typeId: (route.query.type) ? route.query.type : '',
      titleFilter: titleFilter + ' ' + titleSub, */
      selectData: { item: route.query.category, sub: (route.query.sub === undefined ? null : route.query.sub) },
      seo: $replaceSEO(seo.data.data, null, route.query.category, route.query.sub, currCategory.name, ''),
      categories,
      selectedPackagingType,
      selectedProductType,
      currCategory,
      currCategoryId
    }
  },
  data () {
    return {
      slide: 0,
      sliding: null,
      waypointLoaded: [],
      maxImpression: 6,
      productType: null,
      page: 1,
      products: [],
      categoryProductLength: 0,
      pkgId: null,
      pdType: null
    }
  },
  computed: {
    ...mapState(['userData'])
  },
  mounted () {
    this.productType = this.$route.query.type ? parseInt(this.$route.query.type) : null
    /* window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: 'pageInfo',
      page: {
        type: 'category',
        path: `${this.$route.path}` + ((this.packagingTypeId) ? '?category=' + this.packagingTypeId : ''),
        title: this.seo.title
      },
      user: {
        customerID: (this.userData) ? this.userData.uid : '',
        customerEmail: (this.userData) ? this.userData.email : ''
      }
    })
    setTimeout(() => {
      window.$('.read-more').readMore({ previewHeight: 160 })
    }, 1500)
    // console.log(this.productsData.products)
    this.waypointLoaded = [] */
  },
  methods: {
    ...mapMutations(['showLoading', 'hideLoading', 'setAlert', 'setAlertWithLine']),
    productClicked (pattern, indexPT) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: 'productClicked',
        ecommerce: {
          click: {
            actionField: { list: this.titleFilter },
            products: [{
              id: `LOCOPACK_${pattern.packaging_type_id}` + pattern.id.toString().padStart(9, '0'),
              name: pattern.name,
              price: ((pattern.per_pcs) ? parseFloat(pattern.per_pcs).toFixed(2) : 0),
              // category: this.packaing_types[pattern.packaging_type_id - 1].packaging_type_name,
              category: '',
              brand: 'Locopack',
              position: 1,
              dimension1: '',
              dimension2: '',
              dimension3: '',
              dimension4: '',
              dimension5: '',
              dimension6: '',
              dimension7: '',
              dimension8: '',
              dimension9: '',
              dimension10: '',
              dimension11: '',
              dimension12: '',
              dimension13: ''
            }]
          }
        }
      })
    },
    onWaypoint ({ going, direction, el }) {
      // console.log('waypoint going in!', going === this.$waypointMap.GOING_IN, typeof direction === 'undefined', el.getAttribute('data-pattern-index'))
      const inIdx = parseInt(el.getAttribute('data-pattern-index'))
      if ((going === this.$waypointMap.GOING_IN && direction === this.$waypointMap.DIRECTION_TOP) ||
      (going === this.$waypointMap.GOING_IN && typeof direction === 'undefined' && inIdx === 0)) {
        const impressions = []
        let pos = 0
        for (let k = inIdx; pos < this.maxImpression; k++) {
          const item = this.productsData.products[k]
          if (k in this.productsData.products && typeof this.waypointLoaded[item.id] === 'undefined' && this.waypointLoaded[item.id] !== true) {
            impressions.push({
              id: `LOCOPACK_${item.packaging_type_id}` + item.id.toString().padStart(9, '0'),
              name: item.name,
              price: ((item.per_pcs !== null) ? parseFloat(item.per_pcs).toFixed(2) : 0),
              category: this.packaing_types[item.packaging_type_id - 1].packaging_type_name,
              brand: 'Locopack',
              list: this.titleFilter,
              position: (k + 1),
              dimension1: '',
              dimension2: '',
              dimension3: '',
              dimension4: '',
              dimension5: '',
              dimension6: '',
              dimension7: '',
              dimension8: '',
              dimension9: '',
              dimension10: '',
              dimension11: '',
              dimension12: '',
              dimension13: ''
            })
            this.waypointLoaded[item.id] = true
          }
          pos++
        }
        // console.log(impressions)
        if (impressions.length > 0) {
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({ ecommerce: null })
          window.dataLayer.push({
            event: 'impression',
            ecommerce: {
              impressions
            }
          })
        }
      }
    },
    categoryParameter (item) {
      const parmas = {
        category: item.packaging_type_id
      }
      if (item.product_type) {
        parmas.type = item.product_type
      }
      return parmas
    },
    async getFilter () {
      this.showLoading()
      const newFilterData = {}
      for (const x in this.filterData) {
        newFilterData[x] = []
        this.filterData[x].data.forEach((item) => {
          if (item.check === true) {
            if (x === 'sizes') {
              newFilterData[x].push(`${item.min}-${item.max}`)
            } else {
              newFilterData[x].push(item.id)
            }
          }
        })
        newFilterData[x] = newFilterData[x].join()
      }
      newFilterData.packaging_type_id = this.packagingTypeId
      newFilterData.sub_types = this.subTypeId
      newFilterData.without_category = 1
      const products = await this.$axios.$get('/products', { params: newFilterData })

      this.productsData.products = products.products
      this.hideLoading()
    },
    changeCategory (id) {
      this.currCategoryId = id
      this.currCategory = this.categories.find((c) => {
        return c.id === id
      })
      this.$router.replace({
        query: {
          ...this.$route.query,
          category: this.currCategory.packaging_type_id,
          type: this.currCategory.product_type
        }
      })
      this.page = 1
      this.products = []
      this.$refs.infiniteLoading.$emit('$InfiniteLoading:reset')
    },
    onSlideStart () {
      this.sliding = true
    },
    onSlideEnd () {
      this.sliding = false
    },
    setProduct (Product) {
      // this.$ga.event('PurchaseFlow', 'Click', 'CustomProductPattern-' + Product.id)
      this.$refs.modal.ModalProduct = Product
    },
    async loadProducts ($state) {
      try {
        const category = this.categories.find((c) => {
          return c.id === this.currCategoryId
        })
        await this.$axios.get('v2/products', {
          params: {
            page: this.page,
            packaging_type_id: category.packaging_type_id,
            product_type: category.product_type
          }
        }).then((res) => {
          const data = res.data
          this.categoryProductLength = data.total
          if (data.data.length) {
            this.products.push(...data.data)
            if (this.page >= data.last_page) {
              $state.complete()
            } else {
              $state.loaded()
            }
            this.page++
          } else {
            $state.complete()
          }
        })
      } catch (error) {
        console.error('Error loading items:', error)
        $state.error()
      }
    }
  },
  head () {
    return {
      title: this.seo.title,
      meta: [
        { hid: 'description', name: 'description', content: this.seo.description },
        { hid: 'keywords', name: 'keywords', content: this.seo.keywords }
      ],
      link: [
        {
          rel: 'canonical',
          href: this.seo.canonical_url
        }
      ]
    }
  }

}
